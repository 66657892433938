import React from "react";
import {isDefined,count,getSetting, CreateArray} from "../../config/helpers";
import dynamic from "next/dynamic";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import useWindowSize from "../../_hooks/useWindowSize";
import { useSelector } from "react-redux";
import useGlobalSettings from "@components/_hooks/useGlobalSettings";

const DefaultSkeleton = dynamic(() => import("../Reusables/DefaultSkeleton"));
const BoxContainer = dynamic(() => import("../Reusables/BoxContainerV2"));
const HomeProductCarousel = dynamic(() => import("./HomeProductCarousel"));
const HomeSlider = dynamic(() => import("./Slider/HomeSlider"));
const HomeClients = dynamic(() => import("./Clients/HomeClients"), {
	loading: () => (
		<>
			<div className="d-flex justify-space-evenly">
				<Stack spacing={5} direction="row">
					<CreateArray length={6}>
						<DefaultSkeleton width={175} height={150} />
					</CreateArray>
				</Stack>
			</div>
		</>
	),
	ssr: false
});

const HomeArticles = dynamic(() => import("./Articles/HomeArticles"));
const HomeContentMobile = dynamic(() => import("./HomeContentMobile"));
const HomeContentDesktop = dynamic(() => import("./HomeContentDesktop"));
const HomeDescription = dynamic(() => import("./HomeDescription"));
const HomeCategories = dynamic(() => import("./HomeCategories"));

const Home2 = ({ homeData }) => {
	const { getSetting } = useGlobalSettings();
    const { template_settings, template_page_content, } = useSelector((state) => state.globalReducer);
    const { tabs, long_term, template_page_settings, } = homeData;
    const page_content = template_page_content['home'];
    const {isMobile} = useWindowSize();
    const layout = getSetting(
        template_settings,
        template_page_settings,
        "layout",
        "wide"
    );
	
    const { home_grid_columns, home_article_count }= template_page_settings;

    // const layout_width =
	// 		layout == "custom" ?
	// 		getSetting(
	// 			template_settings,
	// 			template_page_settings,
	// 			"custom_layout_width",
	// 			"0px"
	// 		):"0px";

	const clientSettings = () => {
		if(getSetting('our_clients_visible', 'yes') == 'yes'){
			return (
				<Box className="mt-5">
					<HomeClients/>
				</Box>
			)
		}
	}

    return (
			<div  id="home" key="home" className="p-0 mb-5 pt-4 hide-horizontal-overflow">
				<BoxContainer mobileClasses={'p-0 pl-2 pr-2'}>
					{
						!isMobile && (
							<Box>
								<HomeSlider slides={homeData.slides}/>
							</Box>
						)
					}

					<Box className="mb-5">
						{/* <ImageTile imagePass={homeData.categories} home_grid_columns={home_grid_columns}/> */}
						<HomeCategories
							categories={homeData.categories}
							home_grid_columns={home_grid_columns}
						/>
						{page_content.section_1 && <HomeDescription />}
					</Box>

				{/* <Box>
						<HomeStatistics/>
					</Box> */}
			
				</BoxContainer>
				
				<Box className={ (layout == 'box' && !isMobile ) || (!isMobile) ? 'boxed-padding mb-5 mt-6' : ''}>
					{
						isDefined(tabs) && count(Object.keys(tabs)) > 0 && (
							<HomeProductCarousel 
								tabs={tabs}
								long_term={long_term}
								data={homeData}
							/>
						)
					}
				</Box>

				<BoxContainer mobileClasses={'p-0 mt-4'}>
					{isMobile ? <HomeContentMobile/> : <HomeContentDesktop />}
				</BoxContainer>

				<BoxContainer mobileClasses={'p-0 mt-4'}>
				
					<HomeArticles
						home_article_count={home_article_count} 
						articles={homeData.articles}
					/>

					{!isMobile && clientSettings()}

				</BoxContainer>
			</div>
    );

}

export default React.memo(Home2);